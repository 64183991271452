@import 'variables';

html {

    body {

        .address-modal {

            .css-gcr8ku {
                max-height: 90%;
                overflow-y: scroll;
            }

        }

        .size-address {

            font-size: 2rem;
        }

        .address-name {
            text-transform: capitalize;
            font-weight: bold;
            align-content: center;
        }

        .pac-container {
            z-index: 9999999999999;
        }

        .map {
            height: 200px;
            margin-bottom: 3.5rem;
        }

        .line-height {
            line-height: 30px;
        }

        .css-jh47zj-MuiButtonBase-root-MuiButton-root {
            background-color: $secondary-color;

            &:hover {
                background-color: #002e36;
            }
        }


        .css-9y1egq-MuiButtonBase-root-MuiButton-root {
            border: 1px solid $secondary-color-rgb;
            color: $secondary-color;

            &:hover {
                border: 1px solid $secondary-color-rgb;
                color: $secondary-color;
            }
        }

        #searchInput {
            padding: .5rem;
            margin-top: .5rem;
            width: 250px;
            border-radius: 15px;
        }


        .address-not-valid {
            color: red;
            font-weight: bold;
        }

        .z-index-9999 {
            z-index: 99999 !important;
        }


    }
}