@import 'variables';

html {
    body {

        @media (min-width: 2200px) {

            .input-send {
                width: 29%;
            }

            .button-send-text,
            .button-send-img {
                width: 4%;
            }

            .page_size.MuiContainer-root {
                max-width: 1800px;
            }

            .appbar-store-container {
                max-width: 1650px;
            }

        }

        @media (max-width: 1800px) {

            .input-send {
                width: 27% !important;
            }

            .button-send-text,
            .button-send-img {
                width: 4% !important;
                ;
            }

            .button-send-text {
                padding-top: 5px !important;
                padding-bottom: 5px !important;
            }


        }



        @media (max-width: 1550px) {

            .fulfillment-modal{
                width: 70%;
                max-height: 600px;
            }
        }


        @media (max-width: 1400px) {

            .input-send {
                width: 25% !important;
            }

            .button-send-text,
            .button-send-img {
                width: 5% !important;
                ;
            }


            .button-send-text {
                padding-top: 10px !important;
                padding-bottom: 5px !important;
            }

            .main-store {
                padding-top: 2.5%;
            }

            .fulfillment-modal{
                width: 80%;
            }
        }


        @media (max-width: 1200px) {

            .list-chat {
                margin-top: 15%;

                input {
                    width: 26%;
                }
            }


            .address-modal {

                .css-gcr8ku {
                    width: 75%;
                    height: 75%;
                    overflow-y: scroll;
                }
            }

            .main-store {


                padding-top: 4%;

                b {
                    font-size: 14px;
                }

                h4,
                h5 {
                    font-size: 18px;
                }
            }

            .slider-image {
                min-height: auto;
            }
        }


        @media (max-width: 992px) {


            .order-summary {
                position: unset;
                width: 100%;
            }

            .main-store {
                padding-top: 5%;
            }

            .color-navbar {
                font-size: 0.5rem !important;
            }

            .responsive-logo {
                img {
                    width: 150px;
                }
            }

            .chat-sidebar {

                .MuiDrawer-paperAnchorLeft,
                .MuiDrawer-paperAnchorRight {
                    width: 50%;
                }

                header {
                    width: 50%;
                }

                input {
                    width: 40%;
                }

            }


            .input-send {
                width: 40% !important;
            }

            .button-send-text,
            .button-send-img {
                width: 5% !important;
                ;
            }


        }


        @media (max-width: 900px) {


            .responsive-logo {
                img {
                    width: 130px;
                }
            }

            .mobile-option {
                display: block;
            }

            .desktop-option {
                display: none;
            }

            .single_product_add_to_Cart {
                margin-top: 2%;
                width: 100%;
            }

            .quantity-controls {
                width: 100%;
            }

            .display-cart-with-quantity {
                display: block;
            }

            .footer {

                * {
                    text-align: left;
                }

                .MuiGrid-container {

                    margin: auto !important;
                }

                .about_desc {
                    max-width: 100% !important;
                }

                .social-media {
                    margin-top: auto;
                }

            }

            .cart-container {
                display: block;

            }

           .appbar-store {
                top: 10%;
            }


            .fulfillment-modal-content{
              margin-top: 10%;
              row-gap: 50px;
            }

        }


        @media (max-width: 800px) {


            .full-preloader-position {
                left: 45%;
            }

            .rcw-widget-container {
                bottom: 30px;
                right: 15px;
            }


            .input-send {
                width: 38% !important;
            }

            .button-send-text,
            .button-send-img {
                width: 5% !important;
                ;
            }
            
        }


        @media (max-width: 768px) {

            .responsive-logo {
                img {
                    width: 130px;
                }
            }

            .input-profile {
                width: 100%;
            }


            .rcw-widget-container-cart {
                bottom: 100px !important;
            }

            .search-auto-complete {
                min-height: 50px;
                width: 100%;
            }

            .priority-attr {
                width: 100% !important;
            }

        }


        @media (max-width: 600px) {

            .limit-responsive {
                margin-top: 5%;
            }

            .search_by_name {
                display: none;
            }

            .responsive-logo {
                display: none
            }

            #dialog-show-request {

                .css-vxcmzt {
                    display: block;
                }
            }


            .input-send {
                width: 35% !important;
            }

            .button-send-text,
            .button-send-img {
                width: 5% !important;
                ;
            }


            .main-store {

                .align-content-center {
                    margin-top: 5%;
                }
            }

            .sort_responsive,
            .limit_responsive {

                margin-top: 5%;
            }

            .store_page{
                margin-top: 10%;
            }

        }

        @media (max-width: 540px) {

            .cart-item {
                align-items: center;
                background: #ffffff;
                border: 2px solid #0db2ad;
                border-radius: 5px;
                border-radius: 15px;
                color: #000;
                display: flex ;
                font-size: 18px;
                font-weight: 700;
                padding: 1%;
                margin-top: 2%;
                margin-bottom: 2%;
                flex-direction: column;
                text-align: center;
            }

            .cart-item-checkout{
                display: flex ;
                flex-direction: column;
                text-align: center;
                padding: 1%;
            }

            .quantity-controls{
                flex-direction:column
            }

            .refresh-button {
                font-size: 18px !important;
                padding-top: 0px !important;
            }

            .no-padding-top {
                padding-top: 6px !important;
            }

            .address-modal {

                h2 {
                    font-size: 18px;
                }

                button {
                    font-size: 12px;
                }

                #searchInput {
                    width: 150px;
                }

            }


            .input-send {
                width: 55% !important;
            }

            .button-send-text,
            .button-send-img {
                width: 5% !important;
                ;
            }



            .padding-profile {
                padding-right: 0%;
                padding-left: 0%;
            }

            .list-chat {
                margin-top: 15%;
            }

            .chat-sidebar {

                .MuiDrawer-paperAnchorLeft,
                .MuiDrawer-paperAnchorRight {
                    width: 75%;
                }

                header {
                    width: 75%;
                }

                .css-ag7rrr-MuiTypography-root {
                    font-size: 1rem;
                }

                .css-2ulfj5-MuiTypography-root {
                    font-size: 1rem;
                }

                .css-sghohy-MuiButtonBase-root-MuiButton-root {
                    font-size: .7rem;
                }

                input {
                    width: 55%;
                }

            }



            .update-request {

                .MuiBadge-badge {
                    font-size: 10px;
                    height: 10px;
                }
            }


            .box-request {

                font-size: 14px !important;

                .css-37jdci-MuiTypography-root {
                    font-size: 14px !important;
                }



            }

            .main-title {

                .MuiTypography-root {
                    font-size: 1.5rem;
                }

            }


            .request-title {
                font-size: 18px;
            }

            #child-modal-title {
                font-size: 18px;
            }

            .cancel-form {
                width: auto;
            }

            #dialog-show-request {

                z-index: 9999;

                h1 {
                    font-size: 24px;
                }

                h2,
                h3,
                b,
                p {
                    font-size: 16px;
                }

                .MuiPaper-rounded {
                    width: 100% !important;
                }
            }

            #modal-offer {
                .css-vqb47y {
                    width: 400px;
                }

            }

            .add-request {

                .MuiStepper-horizontal {
                    overflow-x: scroll;
                }

                .css-m5vj9m-MuiStepper-root {
                    overflow-x: scroll;
                }

                .css-1hv8oq8-MuiStepLabel-label {
                    font-size: 12px !important;
                }

                .css-1hv8oq8-MuiStepLabel-label {
                    font-size: 12px;
                }

                .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
                    padding: 15px 14px;
                }

                .css-899mdu {
                    margin-top: 10%;
                }

                .MuiStepLabel-label {
                    font-size: 12 px !important;
                }
            }

            .last-section {

                .copy-right {

                    a {
                        font-size: 12px !important;
                    }
                }

                .logo {

                    img {
                        width: 100px !important;
                    }

                }
            }

            .main-store {
                padding-top: 5%;
            }


           .appbar-store{

                .MuiContainer-root{
                    .menu button {
                        font-size: 12px;
                    }

                    .menu {
                        
                        .Dropdown_dropdown__eusxd  {

                            button{
                                font-size: 15px;
                            }
                            
                        }
                    }
        
                }
           }  



           .product-card{
            margin: auto;
           }
        }


        @media (max-width: 420px) {

            .login-form,
            .otp-form {

                .auth-logo {
                    width: 250px;
                }

                .terms-of-service {
                    font-size: 14px;
                }
            }

            #modal-offer {

                .css-vqb47y {
                    width: 320px;
                }

            }


            .input-send {
                width: 50% !important;
            }

            .button-send-text,
            .button-send-img {
                width: 5% !important;
            }


            .main-store {
                padding-top: 5%;
            }

            .search_by_name {
                display: none;
            }


            .store_page{
                margin-top: 20%;
            }

        }


        @media (max-width: 320px) {

            .login-form,
            .otp-form {

                .auth-logo {
                    width: 200px;
                }

                .css-xsqe0f-MuiButtonBase-root-MuiButton-root {
                    font-size: 12px;
                }

                .resend-button {
                    font-size: 12px;
                }
            }

            #modal-offer {

                .css-vqb47y {
                    width: 250px;
                }

            }
        }

    }

}


.arabic {

    @media (max-width: 1200px) {}


    @media (max-width: 992px) {}


    @media (max-width: 900px) {}


    @media (max-width: 800px) {}


    @media (max-width: 768px) {

        .css-ptiqhd-MuiSvgIcon-root {
            transform: rotate(180deg);
        }

        .css-174miue-MuiList-root {

            .css-tlelie-MuiListItemText-root {
                text-align: right;
            }

        }

    }


    @media (max-width: 600px) {}

    @media (max-width: 540px) {}


    @media (max-width: 420px) {}


    @media (max-width: 320px) {}


}