@import 'variables';

html {
    body {

        .form-store-seller {

            .editor-description {

                margin-top: 10%;
                width: 750px;

                .ck-editor__main {

                    .ck-editor__editable_inline {
                        height: 200px;
                        font-family: sans-serif;
                    }
                }

            }

            .img-section {

                margin-top: 10%;

                .img-title {
                    position: relative;
                }

                .product-img {
                    object-fit: contain;
                    height: 350px;
                    width: 300px;
                    aspect-ratio: "11 / 15"; // Enforce aspect ratio
                    margin-top: 2.5%;
                }

                .MuiButton-contained {
                    width: 50%;
                    font-weight: bold;
                    font-size: 14px;
                }

                span {
                    font-size: 25px;
                }

            }

            .specification-section {

                margin-top: 10%;
                width: 900px;

                span {
                    font-size: 25px;
                }

                .delete-specifications {
                    align-self: center;
                }

                .form-specification {
                    margin-top: 2.5%;
                }
            }

            .attribute-form {

                .add-attr {
                    padding: 10%;
                    margin-top: 10%;
                }

                .margin-attribute {
                    margin-top: 10%;
                }
            }


            .details-section {

                margin-top: 2.5%;
                margin-bottom: 2.5%;
                width: 100%;
            }

            .delete-details {
                align-content: center;
            }

            .add-dimension {
                height: 40px;
                align-self: center;
            }

        }

        .details-section {


            .form-details {
                background-color: $background-store;
                padding-left: 2.5%;
                padding-right: 2.5%;
                padding-bottom: 2.5%;
                border-radius: 15px;
                margin-bottom: 2.5%;

                .delete-details {
                    float: right;
                    padding-top: 10px;
                }

                .form-details-inputs {
                    padding-top: 4%;
                    padding-bottom: 4%;
                    row-gap: 25px;
                }

                .custom-attr-list {
                    margin-top: 2.5%;
                }

            }

            .priority-attr {
                width: 25%;
                margin-bottom: 4%;
                margin-top: 2%;
            }


        }

        .product-table-search {
            width: 33.333%;
        }

        .product-table {
            margin-top: 2%;

            .product-img {
                width: 150px;
                height: auto;
                border-radius: 15px;
            }

            .product-details {
                margin-top: 1%;
                position: relative;
                left: 22%;
                right: auto;
                width: 100%;
                margin-bottom: 1%;
            }

            .MuiTablePagination-selectLabel,
            .MuiTablePagination-input {
                display: none;
            }


            .delete-address {
                border-color: red !important;
                font-weight: bold;
                background-color: red !important;
                color: white !important;
            }

        }

        .product-img-view {
            width: 300px;
            height: auto;
            border-radius: 15px;
        }

        .MuiTabs-indicator {
            background-color: $secondary-color;
        }

        .product_published {
            border-radius: 10px;
            background-color: $secondary-color;
            border-color: white;
            padding: 10px;
            color: white;
            font-weight: bold;
            text-transform: uppercase;
            /* Capitalizes the first letter */
            text-align: center;
        }

        .product_draft {
            border-radius: 10px;
            background-color: white;
            color: rgb(0, 0, 0);
            border-color: white;
            padding: 10px;
            font-weight: bold;
            text-transform: uppercase;
            /* Capitalizes the first letter */
            text-align: center;
        }

        .product_rejected {
            border-radius: 10px;
            background-color: red;
            border-color: white;
            padding: 10px;
            color: white;
            font-weight: bold;
            text-transform: uppercase;
            /* Capitalizes the first letter */
            text-align: center;
        }

        .quill {
            height: 200px;
        }

        .crop-container {
            position: relative;
            min-width: 300px;
            min-height: 350px;
            background: #ffffff;
            margin: 2%;
            border-style: solid;
            border-radius: 5px;
            border-color: $primary-color;
            max-width: 1500px;
            max-height: 1100px;
        }

        .crop-btn {
            position: absolute;
            padding: 10px 15px;
            border: none;
            background-color: #28a745;
            color: white;
            cursor: pointer;
            border-radius: 5px;
        }

        .crop-btn:hover {
            background-color: #218838;
        }


        .max-height-img{
            max-height: 500px;
            width: auto;
        }


        th{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .reactEasyCrop_CropArea{
            width: 300px !important;
            height: 350px !important;
        }


        .specialWarranty{
            color: black;

            textarea{
                color: black;
            }
        }


    }

}