@import 'variables';

html {

    body {


        .resend-button {
            background: $secondary-color;

            &:hover {
                background-color: $primary-color-rgb;
            }
        }

        .login-form,
        .otp-form {
            margin-top: 8rem;
            display: 'flex';
            flex-direction: 'column';
            align-items: 'center';

            .margin-logo {
                margin: 15%;
            }
        }

        .form-title {
            text-align: center;
            font-size: "30px";
        }

        .css-xsqe0f-MuiButtonBase-root-MuiButton-root {
            border: 1px solid $secondary-color-rgb;
            color: $secondary-color;
        }

        .css-xsqe0f-MuiButtonBase-root-MuiButton-root:hover {
            border: 1px solid $secondary-color;
        }

        .terms-of-service {
            color: $primary-color;
            font-weight: bold;
            text-decoration: none;
        }



    }
}