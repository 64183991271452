@import 'variables';

html {
    body {

        .order-table {
            margin-top: 2%;

            .order-img {
                width: 150px;
                height: auto;
                border-radius: 15px;
            }

            .order-details {
                margin-top: 1%;
                position: relative;
                left: 30%;
                right: auto;
                width: 100%;
                margin-bottom: 1%;
            }

            .order-details-seller {
                margin-top: 1%;
                position: relative;
                left: 15%;
                right: auto;
                width: 100%;
                margin-bottom: 1%;
            }

            .MuiTablePagination-selectLabel,
            .MuiTablePagination-input {
                display: none;
            }


            .cancel-order {
                border-color: red !important;
                font-weight: bold;
                background-color: red !important;
                color: white !important;
            }

            .tracking-button {
                background-color: $primary-color;
                padding: 12px;
                border-radius: 15px;
                color: white;
                font-weight: bold;
                text-decoration: none;
            }

            .product-review {
                border-color: gold !important;
                font-weight: bold;
                background-color: gold !important;
                color: white !important;
            }

            .store-review {
                border-color: rgb(18, 238, 6) !important;
                font-weight: bold;
                background-color: rgb(18, 238, 6) !important;
                color: white !important;
            }

        }


        .order-view {

            .MuiDialog-container {
                .MuiPaper-elevation {
                    width: 100%;
                }
            }

        }

        .product-review-stars {
            margin-bottom: 5%;

            .MuiSvgIcon-root {
                fill: gold;
            }
        }

        .fulfillment_fee{
            font-weight: bold;
            color: $secondary-color;
            white-space: nowrap;
        }

        .fulfillment-text{
            white-space: nowrap;
        }


        .fulfillment-modal{
            overflow: auto;
        }

        .fulfillment-modal-content{
            max-height: 600px;
            overflow-y: auto;
            overflow-x: hidden;
            padding-top: 3%;
        }
    
    
    }
}