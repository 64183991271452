@import 'variables';

html {

    overflow-x: hidden !important;

    body {


        overflow-x: hidden !important;
        padding-right: unset !important;
        margin: 0px;


        .page_size.MuiContainer-root {

            max-width: 1600px;

        }

        .error {
            color: red !important;
            font-weight: bold !important;
        }


        .padding-outlet {

            padding-top: 1%;
            padding-bottom: 10%;
            min-height: 800px;
            padding-right: 3%;
            padding-left: 3%;

            .preloader-position {
                position: relative;
                left: 50%;
                right: auto;
                padding-top: 20%;
                padding-bottom: 20%;
                color: $primary-color-rgb !important;
            }

        }


        .full-preloader-position {
            position: fixed;
            top: 50%;
            left: 50%;
            right: auto;
            width: 75px !important;
            height: 75px !important;
            color: $primary-color-rgb !important;
        }

        .space-around-tabs {

            .MuiTabs-scroller {

                .MuiTabs-flexContainer {
                    justify-content: space-around
                }
            }
        }

        .color-navbar {
            color: $secondary-color;
        }



        .margin-auto {
            margin: auto;
        }

        .navbar-background {
            background: $primary-color-rgb;
            height: 80px;

            button {
                color: white;
                font-weight: bold;
            }

            .MuiTabs-indicator {
                background-color: $secondary-color;
            }


            .MuiButtonBase-root {
                svg {
                    font-size: 26px;
                }
            }

            .font-size-30 {
                svg {
                    font-size: 30px;
                }
            }
        }


        .footer-background {

            background: $primary-color-rgb;

            button {
                color: white;
                font-weight: bold;
            }

            .padding-footer {
                padding-right: 3%;
                padding-left: 3%;
            }


        }




        .notification-pos {
            position: relative;
            left: 50%;
        }


        .search-input {
            background: #ffffff;
            color: #4f79a2;
            border-radius: 10px;
            font-weight: bold;
        }

        .bold {
            font-weight: bold;
        }

        .no-padding {
            padding-left: 0;
            padding-right: 0;
        }

        .box-shadow-unset {
            box-shadow: unset;
        }

        .hidden {
            display: none;
        }

        .css-aym9vq-MuiButtonBase-root-MuiTab-root {
            font-weight: 600 !important;
            font-size: 0.9rem !important;

        }

        .css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
            color: $secondary-color;
        }


        .css-1aquho2-MuiTabs-indicator {
            position: absolute;
            height: 2px;
            bottom: 0;
            width: 100%;
            -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            background-color: $secondary-color;

        }

        .css-urznti-MuiContainer-root {

            * {
                color: white;
            }
        }


        .css-1orzy9s {
            background-color: $primary-color-rgb;
        }

        .css-vih0ba-MuiGrid2-root {
            padding-top: 24px;
        }

        .padding-main-logo {
            padding-bottom: 0px;
        }

        .mobile-option {
            display: none;
        }

        .mobile-button {
            color: white;
        }

        .navbar-color {
            background-color: $primary-color;
            padding: 5%;
        }

        .notify-padding {
            padding-top: 3%;
            padding-bottom: 3%;
            font-weight: bold;
        }

        .notify {
            background-color: $secondary-color !important;
            color: white !important;
        }

        button.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorPrimary.color-navbar.css-1tnnsql {
            font-weight: bold !important;
        }



        .footer {

            * {
                text-decoration: none;
                color: white;
                text-align: left;

                .about_desc {
                    padding: 3%;
                    max-width: 85%;
                }
            }

            .social-media {
                margin-top: 2%;

                svg {
                    border: solid;
                    padding: 8px;
                    border-radius: 25px;
                    margin: 5px;
                    background-color: #3f3f3f;
                    border-color: #3f3f3f;

                    &:hover {
                        background-color: $secondary-color;
                        border-color: $secondary-color;
                    }
                }
            }

            .links {

                a {
                    &:hover {
                        color: $secondary-color !important;
                    }
                }

            }

            .last-section {
                background-color: white;
                padding: 10px 0px 10px 0px;
                position: relative;

                .MuiContainer-root {

                    display: flex;

                    .logo {
                        width: 70%;
                        height: auto;

                        img {
                            width: 134px;
                        }
                    }

                    .pos-copy-right {
                        width: 30%;
                        margin: auto;
                    }

                    .copy-right {

                        a {
                            text-align: right;
                            color: $secondary-color;
                            font-weight: 700;
                            font-size: 16px;
                        }
                    }

                }

            }
        }


        .store-status {

            div {
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 0%;
            }

        }

    }

}