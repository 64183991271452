@import 'variables';

:root {
    --swiper-theme-color: $primary-color;
}

#recaptcha-container {
    display: none;
}

html {
    body {

        /* class material ui */

        .text-center{
            text-align: center;
        }
        
        .w-25 {
            width: 25%;
        }

        .w-75 {
            width: 75%;
        }


        .w-60 {
            width: 60%;
        }

        .w-40 {
            width: 40%;
        }

        .MuiButtonBase-root{
            border-radius: 10px;
        }


        .MuiButton-contained {
            background-color: $secondary-color !important;
            color: white !important;
        }

        .no-bold {
            font-weight: 100;
        }

        .Mui-disabled {
            background-color: rgba(0, 0, 0, 0.12) !important;
        }

        .MuiStep-horizontal {

            * {
                background-color: unset !important;
            }

        }

        .MuiBadge-badge {
            background-color: red !important;
        }


        .Mui-checked {
            color: $secondary-color !important;
        }

        .MuiButton-outlined:not(.accept, .reject, .delete-address) {
            color: $secondary-color !important;
            border-color: $secondary-color !important;
        }

        .Mui-active {
            color: $secondary-color !important;
            font-weight: bold !important;
        }

        .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
            color: $secondary-color !important;
            border-color: $secondary-color !important;
        }


        .Mui-focused {

            color: $secondary-color !important;

            * {
                border-color: $secondary-color !important;
            }

            input {
                color: black !important;
            }
        }


        .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            color: $secondary-color !important;
            border-color: $secondary-color !important;
        }

        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            color: $secondary-color !important;
            border-color: $secondary-color !important;
        }

        .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            color: $secondary-color !important;
            border-color: $secondary-color !important;
        }

        .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
            color: $secondary-color !important;
        }


        .css-18lrjg1-MuiCircularProgress-root {
            color: $primary-color-rgb !important;
        }


        .Mui-selected {
            color: $secondary-color !important;
            font-weight: bold !important;
        }

        .css-ttwr4n {
            background-color: $secondary-color !important;
        }

        .css-1p0wue5 {
            height: 46px !important;
        }

        .Mui-completed {
            color: $primary-color-rgb !important;
        }

        /* */


        .font-bold {
            font-weight: bold;
        }

        .table-button {
            background-color: $secondary-color;

            &:hover {
                background-color: $secondary-color;
            }

        }

        .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
        .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
            color: $secondary-color;
        }

        .font-weight-600 {
            font-weight: 600;
        }


        .mt-2 {
            margin-top: 2rem;
        }

        .mt-1 {
            margin-top: 1rem;
        }

        .mb-1 {
            margin-bottom: 1rem;
        }

        .mb-2 {
            margin-bottom: 2rem;
        }

        .mb-0-5 {
            margin-bottom: .5rem;
        }
      
        .ml-1 {
            margin-left: 1rem;
        }

        .mr-0-5 {
            margin-right: 0.5rem;
        }

        .mt-0-5 {
            margin-top: .5rem;
        }

        .mt-0-3 {
            margin-top: .3rem;
        }

        .pt-2 {
            padding-top: 2rem;
        }

        .p-1 {
            padding: 1rem;
        }

        .p-0 {
            padding: 0rem
        }

        .text-undecoration {
            text-decoration: none;
        }

        .display-inline-flex {
            display: inline-flex;
        }

        .float-right{
            float: right;
        }

        .all-request,
        .all-address {

            .box-request {
                margin-top: 2%;
                margin-bottom: 2%;
                border-radius: 15px;
                color: black;
                background: rgba(0, 0, 0, 0.04);
                font-weight: bold;
                padding: 2.5%;
                font-size: 18px;
                border-color: rgba(0, 0, 0, 0.04);

                .dir-rtl {
                    direction: rtl;
                }

            }

            .box-request:hover {

                background-color: $secondary-color;
                color: white !important;

                .css-37jdci-MuiTypography-root {
                    color: white;
                }

                .css-i4bv87-MuiSvgIcon-root {
                    color: white;
                }
            }

            .active {
                background-color: $secondary-color;
                color: white;
            }

        }

        .select-address {

            display: flex;
            gap: 15px;

            .box-request {
                margin-top: 2%;
                margin-bottom: 2%;
                border-radius: 15px;
                color: black;
                background: rgba(0, 0, 0, 0.04);
                font-weight: bold;
                padding: 3%;
                font-size: 18px;
                border-color: rgba(0, 0, 0, 0.04);
                width: 100%;
            }

            .box-request:hover {

                background-color: $secondary-color;

                .css-37jdci-MuiTypography-root {
                    color: white;
                }

                .css-i4bv87-MuiSvgIcon-root {
                    color: white;
                }
            }

            .active {
                background-color: $secondary-color;
                color: white;
            }
        }


        .all-payment {

            .box-request {
                margin-top: 2%;
                margin-bottom: 2%;
                border-radius: 15px;
                color: black;
                background: rgba(0, 0, 0, 0.04);
                font-weight: bold;
                padding: 2.5%;
                font-size: 18px;
                border-color: rgba(0, 0, 0, 0.04);

                .dir-rtl {
                    direction: rtl;
                }

            }

            .box-request:hover {

                background-color: $secondary-color;
                color: white;

                .css-37jdci-MuiTypography-root {
                    color: white;
                }

                .css-i4bv87-MuiSvgIcon-root {
                    color: white;
                }

                .payment-description {
                    color: white;
                }
            }

            .active {
                background-color: $secondary-color;
                color: white;

                .payment-description {
                    color: white;
                }
            }

        }

        .main-logo {
            width: 150px;
            margin-top: 5%;
        }

        .auth-logo {
            width: 300px;
        }




        .css-sghohy-MuiButtonBase-root-MuiButton-root {
            background-color: $secondary-color;
        }

        .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {

            background-color: $primary-color-rgb;

        }

        .css-1vhaqj4-MuiButtonBase-root-MuiButton-root {
            background-color: $secondary-color;
        }

        .css-1vhaqj4-MuiButtonBase-root-MuiButton-root:hover {

            background-color: $primary-color-rgb;

        }

        .css-1wgcmb5-MuiButtonBase-root-MuiButton-root {
            border: 1px solid $secondary-color;
            color: $secondary-color;
        }

        .css-1wgcmb5-MuiButtonBase-root-MuiButton-root:hover {
            border: 1px solid $primary-color-rgb;
            color:$primary-color-rgb;
        }

        .css-18lrjg1-MuiCircularProgress-root {
            display: inline-block;
            color: $primary-color;
            -webkit-animation: animation-61bdi0 1.4s linear infinite;
            animation: animation-61bdi0 1.4s linear infinite;
        }


        .red {
            color: red;
        }

        .button-outlined {

            color: $secondary-color;
            border: 1px solid $secondary-color-rgb;

            &:hover {
                border: 1px solid $secondary-color;
            }

        }

        .button-verify {

            border: 1px solid #F44336;
            color: red;

            &:hover {
                border: 1px solid #F44336;
            }

        }


        .show-error {
            .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
                background-color: #F44336 !important;
                color: white !important;
                font-weight: bold;
            }

            .MuiSnackbarContent-root {
                background-color: #F44336 !important;
                color: white !important;
                font-weight: bold;
            }
        }

        .primary-color {
            color: $primary-color-rgb;
        }



    }


    // update scroll style

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $primary-color-rgb;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $secondary-color;
    }


}