@import 'variables';

html {
    body {

        .css-1hv8oq8-MuiStepLabel-label.Mui-active {
            font-weight: bold;
        }

        .package-size {
            margin-left: 30px;
            color: #6a7484;
            margin-bottom: 1rem;
        }

        .payment-description {
            padding-left: 5%;
            font-size: 16px;
            color: #514f4f;
        }

        .path-alert.MuiPaper-elevation {
            color: $primary-color;
            background-color: rgb(245 245 245);
        }

        .path-alert.MuiAlert-icon {
            color: $primary-color-rgb;
        }

        .delivery-time {
            padding-left: 12%;
            font-size: 16px;
            color: #514f4f;
        }

        .box-request:hover {

            .delivery-time {
                color: white;
            }
        }

        .active {

            .delivery-time {
                color: white;
            }
        }

        .add-request {

            .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
                color: $primary-color;
            }

            .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
                color: $primary-color;
            }

            .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
                border: 1px solid $primary-color-rgb;
                color: $primary-color;
            }

        }

        .img-request {
            width: 50%;
            height: auto;
            margin-bottom: 5%;
            border-style: groove;
            border-color: $secondary-color;
            border-radius: 20px;
        }

        .map-info {
            display: inline;
            font-weight: bold;
        }

    }
}