
html {
  body {

    .document-name {
      text-transform: capitalize;
    }

    .img-store{
      margin: auto;
      img{
        width: 100%;
        text-align: center;
      }
    }

  }
}