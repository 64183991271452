@import 'variables';

html {

    body {

        .card-seller-report {

            :hover {
                background-color: $secondary-color;
                color: white;
            }
        }
    }
}