html {

    body {

        .input-profile {

            .MuiFormControl-root {
                margin-bottom: 15px;
            }

        }

        .profile-avatar {
            width: 200px;
            height: 200px;
        }
    }

}