@import 'variables';

html {
    body {

        .sidebar-top{
            width: auto;
            padding:5%;
            min-height: 600px;
        }

        .sidebar-close{
            left: 90%;
            right: 0%;
            top: 3%;
            position: fixed;
        }

        .search-term {
            .MuiTextField-root {
                width: 100%;
            }
        }

        .font-size-14 {
            font-size: 14px;
        }

        .padding-top-3 {
            padding-top: 1%;
        }

        .product-details-breadcrumb {
            align-self: center;
        }

        
        .category-title-store-page{
            margin-bottom: 1rem;
            margin-top: 1rem;
            color: $primary-color;
            font-weight: bold;
        }


        .mt-3-percent {
            .MuiGrid2-root {
                margin-top: 1%;
            }
        }

        .mt-6-percent {
            margin-top: 6%;
        }

        .section-product-related {
            margin-top: 10%;
        }


        .products {
            .MuiCard-root {
                max-width: 300px;
            }

            .MuiGrid2-root {
                margin-top: 2%;
            }

            .MuiButton-contained {
                width: 100%;
            }

            min-height:400px;
        }

        .search-auto-complete {

            min-height: 50px;

            .focused,
            .unfocused {
                max-width: 70%;
            }

        }

        .attribute_name {
            margin-bottom: 5px;
        }

        .category_list {
            margin-left: 1.5rem;
        }

        .show-more {
            color: $secondary-color;
            font-weight: bold;
            text-align: left;
            margin-top: 5%;
        }

        .MuiSlider-colorPrimary {
            color: $secondary-color;
        }

        .MuiCheckbox-colorPrimary {
            color: $secondary-color !important;
        }

        .appbar-store {

            background: rgba(13, 178, 173, 0.1294117647);
            position: relative;
            top: 5%;
            max-width: 100%;

            .MuiSvgIcon-root {
                fill: $primary-color-rgb;
            }

            .MuiSvgIcon-fontSizeSmall {
                font-size: 30px !important;
            }

            .MuiContainer-root {

                .menu {

                    .Mui-selected {
                        color: $primary-color !important;

                        &:hover {
                            background: $primary-color !important;
                            color: white !important;
                            border-radius: 5px;
                        }

                    }


                    .category_active {
                        color: white !important;
                        background-color: $primary-color !important;
                        font-weight: bold !important;
                        border-radius: 5px;

                        &:hover {
                            color: white !important;
                            background-color: $primary-color !important;
                        }
                    }


                    .Dropdown_dropdown__eusxd {

                        button {
                            font-size: 18px;
                        }
                    }

                    button {
                        background-color: #e0f5f4;
                        color: $primary-color;
                        font-size: 14px;
                        font-weight: 700;

                        padding: 0.5%;

                        white-space: nowrap;
                        /* Prevents text from wrapping */
                        overflow: hidden;
                        /* Hides overflowing content */
                        text-overflow: ellipsis;
                        /* Adds "..." at the end */

                        &:hover {
                            background: $primary-color;
                            color: white;
                            border-radius: 5px;
                        }
                    }

                    li {
                        font-size: 15px;
                        font-weight: 700;
                        color: $primary-color;

                        max-width: 200px;
                        /* Adjust width to fit your layout */
                        word-wrap: break-word;
                        white-space: normal;


                        &:hover {
                            background-color: $primary-color;
                            color: white;
                        }
                    }

                }
            }


            .all-category {
                padding: 7% !important;
                border: unset;
                width: 150px;
                color: $secondary-color !important;

                &:hover {
                    background: $secondary-color !important;
                    color: white !important;
                    border-radius: 5px;
                }
            }

        }

        .product-pagination {
            .MuiPagination-root {

                margin: 5% auto 5% auto;
            }
        }

        .product-search {

            .MuiTextField-root {
                width: 100%;
            }
        }


        .limit {
            width: 90%;
        }

        .sort_product {
            font-weight: bold;
            font-size: 14px;

            &:hover {
                color: $primary-color;
            }

        }

        #sort_product_input {
            font-size: 14px;
        }

        .sidebar-preloader-position {
            position: fixed;
            top: 30%;
            left: 50%;
            right: auto;
            width: 50px !important;
            height: 50px !important;
            color: $primary-color-rgb !important;
        }

        .product-preloader-position {
            position: fixed;
            top: 50%;
            left: 50%;
            right: auto;
            width: 50px !important;
            height: 50px !important;
            color: $primary-color-rgb !important;
        }

        .product-img {
            object-fit: contain;
            height: 350px;
            width: 300px;
            aspect-ratio: 11 / 15, // Enforce aspect ratio
        }

        .attribute-name {
            overflow: hidden;
            width: 100%;
        }

        .clear-price {
            margin-left: 10px;
            font-weight: 500;
            margin-right: 10px;
            color: $secondary-color;
        }

        .border-selected {
            border: solid;
            width: max-content;
            padding-right: 2%;
            border-radius: 15px;
            border-color: $primary-color;
            padding-left: 2%;
            padding-top: .5%;
            padding-bottom: .5%;
            color: $primary-color;
            font-weight: bold;
        }


        .product-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            font-size: 16px;
        }

        .product-card{
            border-radius: 10px;

            label{
                border-radius: 10px;
            }
        }
        
        .price-on-card{
            text-align: center;
            align-items: center;
            justify-content: center;
            margin: auto;

            .last-price{
                font-size: 18px;
                font-weight: bold;
            }
        }


        .slider-product-card{

            .product-card{
                width: 300px;
                margin-bottom: .5%;

                .product-title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 250px;
                    font-size: 16px;
                }
            }

        }

 

        .product-description {
            height: 100px;
            width: 100%;

        
            overflow-y: auto;
            text-overflow: ellipsis;

            *{
                font-size: 14px  !important;
                color: black  !important;
                background-color: white !important;
            }
         
        }

        .product-description-single-page {
            *{
                font-size: 15px  !important;
                color: black  !important;
                background-color: white !important;
            }
        }

        .product-actions {
            height: 75px;
            width: 100%;
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .category-name {
            width: 100%;
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .category-gap-column {

            .MuiTabs-flexContainer {
                column-gap: 20px;
            }

        }

        .category-arrow {
            width: 10%;
        }


        .Dropdown_dropdown__eusxd{
            li:hover{
                .MuiSvgIcon-root  {
                    fill: #ffffff;
                }
            }
        }


        .FullImageZoom {
          
            width: 100%;
            grid-area: 1 / 2 / 4 / 5;
            aspect-ratio: "11 / 15"; // Enforce aspect ratio
            background-color: white;

            img{
                object-fit: contain;
                max-height: 800px;
            }
        }


        .gallery {
            position: relative;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            /* 3 equal columns */
            grid-column-gap: 10px;
            grid-row-gap: 5px;
            margin-top: 3%;

            img {
                width: 100%;
                height: 166.266px;
                object-fit: contain;
                cursor: pointer;
            }

            .img-active {
                border-style: solid;
                border-color: $secondary-color;
                border-radius: 10px;
            }

            .swiper-pagination-bullet-active {
                opacity: var(--swiper-pagination-bullet-opacity, 1);
                background: $secondary-color;
            }

            .swiper-android .swiper-slide, .swiper-ios .swiper-slide, .swiper-wrapper {
                height: 200px;
            }

            .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
                bottom: 0px;
            }
        }

        .attribute-product {
            margin-top: 5%;
            margin-bottom: 5%;
            background: $background-store;
            padding: 4%;
            border-radius: 10px;
        }

        .w-add-cart-single-product {
            width: 70%;
            font-weight: bold;
            border-radius: 10px;
        }

        .w-counter-single-product {
            width: 30%;
            font-weight: bold;
        }

        .single-attribute {
            background: #fdfbff;
            padding: 3%;
            border-radius: 15px;

            p {
                font-weight: 600;
                color: $primary-color-rgb;
            }
        }

        .rcw-widget-container-cart {
            bottom: 85px !important;
        }

        .ShoppingCartButton {
            fill: $primary-color-rgb;
        }

        .sold_out {
            font-weight: bold;
            color: red;
            margin-left: 0px;
            margin-right: 0px;
            text-align: center;
        }

        .w-100 {
            width: 100%;
        }

        .button_cart {
            margin: 5px;
        }


        .price {
            font-size: 15px;
        }

        .sale-price {
            text-decoration: line-through;
            color: red;
            position: relative;
            top: 5px;
            font-size: 14px;
            left: 20px;
        }

        .sale-price-single-page {
            text-decoration: line-through;
            color: red;
            position: relative;
            top: 5px;
            font-size: 16px;
        }

        .show-price {
            display: inline-flex;
            column-gap: 5px;
        }

        .product-tab-details {

            .MuiTabs-indicator {
                background-color: $secondary-color;
            }
        }

        .specification_key {
            text-align: left;
            font-weight: bold;
        }

        .specification_value {
            text-align: center;
        }

        .customer_review_name {
            text-align: left;
        }


        .product_review_section {
            margin-bottom: 1rem;
            margin-top: 15%;
        }

        .tag-style {
            border-style: solid;
            border-radius: 10px;
            padding: 1%;
            margin-right: 1%;
            margin-left: 1%;
            background: $background-store;
            color: $primary-color;
            border-color: $background-store;
            font-weight: bold;
            line-height: 50px;
        }

        .show_more_review {
            width: 75%;
            margin: auto !important;
            margin-top: 2rem !important;
        }

        .out_of_5 {
            margin-left: 2%;
        }

        .slick-image {
            margin-right: 10px;
            margin-left: 10px;
        }

        .slider-product {
            .slick-slide {
                margin-right: 10px;
                margin-left: 10px;
            }

            .slick-list {
                height: 300px;
            }
        }

        .product_related_text {
            color: black;
            text-decoration: none;
            padding: 10px;
        }

        .appbar-store-container {
            padding-right: 2% !important;
            padding-left: 2% !important;
        }

        .cart-page {
            padding: 20px;
            font-family: Arial, sans-serif;
        }

        .cart-container {
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }

        .cart-items {
            flex: 2;
        }

        .cart-items-checkout {
            flex: 2;
            max-height: 600px;
            overflow-y: auto;
        }

        .cart-item {
            align-items: center;
            background: #ffffff;
            border: 2px solid $secondary-color;
            border-radius: 10px;
            color: #000;
            display: flex;
            font-size: 18px;
            font-weight: 700;
            padding: 2.5%;
            margin-top: 2%;
            margin-bottom: 2%;
        }

        .brand-title{
            color: $primary-color;
            text-transform: capitalize;
            font-weight: bold;
        }

        .not_review_yet{
            color: $primary-color;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 2.1%;
        }

        .cart-item-checkout {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            border: 2px solid $secondary-color;
            border-radius: 5px;
            margin: 2%;
            border-radius: 10px;
            color: black;
            background: #ffffff;
            font-weight: bold;
            padding: 2.5%;
            font-size: 18px;
        }

        .cart-item-image {
            width: 150px;
            height: auto;
            object-fit: cover;
            margin-right: 20px;
            border-radius: 15px;
        }

        .cart-item-details {
            flex: 1;
        }

        .quantity-controls {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 40%;
        }

        .quantity-controls button {
            padding: 5px 5px;
            cursor: pointer;
            font-weight: bold;
            border-radius: 10px;
        }

        .quantity-controls button:hover {
            background-color: #e0e0e0;
        }

        .button_cart {
            border-radius: 20px;
        }

        

        .remove-item {
            color: red;
            border: none;
            padding: 5px 10px;
            cursor: pointer;
            float: right;
            font-size: 14px;
            font-weight: bold;
            text-decoration: underline;
            float: revert !important;
        }

        .clear_cart {
            height: 30px;
            align-items: center;
            align-self: center;
            margin-left: 20px;
        }

        .cart-summary {
            flex: 1;
            border: 1px solid #ddd;
            padding: 20px;
            border-radius: 5px;
            height: 100%;
            margin-top: 1.2%;
        }

        .cart-summary h3 {
            margin-bottom: 20px;
        }

        .checkout-button {
            width: 100%;
            color: white;
            border: none;
            cursor: pointer;
            font-weight: bold;
            border-radius: 10px;
        }

        .cart-details {
            font-size: 14px;
            padding-bottom: 2%;
            min-height: 50px;
        }

        .order-summary {
            position: fixed;
            width: 25%;
            top: 22%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            /* Prevents content from breaking layout */
        }

        .checkout-component {
            .MuiPaper-root {
                margin-bottom: 1.5rem;
            }
        }

        .checkout-select-location {
            background-color: $secondary-color !important;
            color: white !important;
            padding-top: 10px;
            padding-bottom: 10px;
            font-weight: bold;
        }

        .address-section {
            max-height: 400px;
            overflow: auto;
        }

        .address-border {
            margin-bottom: .5rem;
            margin-top: .5rem;
        }

        .checkout-error {
            padding: 1%;
            text-align: center;
            color: red;
        }


        .cart_not_matching {
            color: $secondary-color;
            font-size: 20px;
        }

        .align-content-center {
            align-content: center;
        }

        .slick-slide {
            margin: 0 10px;
        }

        .slider-item {
            display: flex;
            justify-content: center;
            align-items: center;
            border-style: solid;
            border-radius: 15px;
            border-color: $primary-color-rgb;
        }

        .slider-image {
            width: 100%;
            border-radius: 8px;
            height: 100%;
            min-height: 248.625px;
        }

        .search_by_name {
            align-content: center;
            text-align: center;
            color: $secondary-color !important;

            b {
                font-weight: bold;
            }
        }


        .slider-container {

            div {

                &:first-child {
                    margin-bottom: 2rem;
                }

            }

            .slider-item {
                display: flex !important;
            }
        }

        .slider-ads {
            margin-top: 4%;
        }


        .product-border {
            margin-top: 5%;
            padding: 3%;
            background-color:$background-store;
            border-radius: 15px;
            border-style: ridge;
            border-color: #ffffff;
        }


        .slider-category {


            .slick-prev:before,
            .slick-next:before {
                color: $primary-color;
                font-size: 25px;
            }

            .slick-list {
                height: 270px;
            }

            .category-slider-container {
                padding: 3%;
                background-color: $background-store;
            }

            .category-slider-item {
                text-align: center;
                padding: 10px;
            }

            .category-image-wrapper {
                width: 200px;
                height: 200px;
                margin: 0 auto;
                border-radius: 50%;
                overflow: hidden;
                border: 2px solid $primary-color;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .category-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .category-name {
                margin-top: 10px;
                font-size: 18px;
                font-weight: bold;
                color: $primary-color;
                text-align: center;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

            }
        }


        .slider-brands {

            margin-top: 5%;

            .slick-prev:before,
            .slick-next:before {
                color: $primary-color;
                font-size: 25px;
            }

            .slick-list {
                height: 270px;
            }

            .brand-slider-container {
                padding: 3%;
                background-color: white;
            }

            .brand-slider-item {
                text-align: center;
                padding: 10px;
            }

            .brand-image-wrapper {
                width: 200px;
                height: 200px;
                margin: 0 auto;
                border-radius: 50%;
                overflow: hidden;
                border: 2px solid $primary-color;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .brand-image {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            .category-name {
                margin-top: 10px;
                font-size: 18px;
                font-weight: bold;
                color: $primary-color;
                text-align: center;
            }
        }

        .sold_by {
            font-weight: bold;
            color: $primary-color;
            margin-bottom: auto;
            font-size: 15px;
        }

        .sold_by_description {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            padding-top: .5rem;
            padding-bottom: .5rem;
        }

        .sold_info {
            max-width: 90%;
        }


        .section-product-related {
            .slick-slider {
                display: flex !important;
            }

            .slick-list {
                display: flex !important;
                align-items: center;
                /* Center images */
                overflow: hidden;
            }

            .slick-track {
                display: flex !important;
            }

            .slick-slide {
                display: flex !important;
                justify-content: center;
                align-items: center;
            }

            .slick-image {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

        }
          


    }
}