@import 'variables';

html {

    body {

        .mySwiper {

            margin-top: 1%;
            margin-bottom: 2%;

            .swiper-button-prev,
            .swiper-button-next {
                color: #ffffff;
                font-weight: bold;
            }

            .swiper {
                width: 100%;
                height: 100%;
            }

            .swiper-slide {
                background-position: center;
                background-size: cover;

            }

            .swiper-slide {

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            .swiper-pagination-bullet-active {
                background: #ffffff;
            }
        }

        .css-74bi4q-MuiDataGrid-overlayWrapper {
            height: 20px !important;
        }

        .offer-time {
            white-space: pre-wrap;
            color: #a5a1a1;
            font-size: 14px;
            margin-top: 5px;
        }

        .drive-name {
            font-size: 18px;
        }

        .reference {
            padding-left: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            color: #a5a1a1;
        }



        .dialog-delivery-time {

            .date-dialog {
                margin: 0px;
                padding: 17px 0px;
            }
        }

        #dialog-show-request {

            .MuiPaper-root {
                width: 50%;
            }
        }


        .review-request {
            overflow-y: auto;
            height: 250px;
            margin-bottom: 2rem;


            button {

                svg {
                    &:not(.send-review) {
                        color: gold !important;
                    }

                }
            }


            .css-bhp9pd-MuiPaper-root-MuiCard-root {
                border-style: outset;
                border-radius: 15px;
            }
        }

        .css-pdct74-MuiTablePagination-selectLabel {
            font-size: 14px;
        }

        .star-color {
            svg {
                color: gold;
            }
        }


        .request_icon {
            margin-right: 8px;
        }


        .back-to-home {
            font-size: 40px;
            color: $primary-color;
            padding-top: .5%;
            padding-bottom: .5%;
        }

        .refresh-button {
            svg {
                color: $secondary-color;
            }
        }

        .offer-now {
            padding-right: 5%;
            padding-left: 5%;
        }


    }
}