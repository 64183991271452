@import 'variables';

.arabic {

    direction: rtl;

    * {
        font-family: sans-serif !important;
    }


    .swiper {

        * {
            direction: ltr !important;
        }
    }

    input {
        text-align: right;
    }

    .notification-pos {
        right: 50%;
        left: 0%;
    }

    .text-align-right {
        text-align: right;
    }


    .all-request {

        .box-request {

            .dir-rtl {
                direction: ltr;

                svg {
                    transform: rotate(180deg);
                }
            }
        }

    }

    .all-address {
        .box-request {

            .dir-rtl {
                direction: ltr;
            }
        }
    }

    .css-1vnn4cn-MuiSvgIcon-root {
        margin-left: 4px;
        margin-right: 0px;
    }

    .css-vnkopk-MuiStepLabel-iconContainer {
        padding-left: 8px;
        padding-right: 0px;
    }

    .css-j204z7-MuiFormControlLabel-root {
        margin-right: -11px;
        margin-left: 16px;
    }

    .css-1wgcmb5-MuiButtonBase-root-MuiButton-root {
        margin-left: 8px;
        margin-right: 0px;
    }



    .icon-arabic {
        .css-i4bv87-MuiSvgIcon-root {
            margin-left: 8px !important;
            margin-right: 0px !important;
        }

        .MuiSvgIcon-root {
            margin-left: 8px !important;
            margin-right: 0px !important;
        }

        .MuiFormControlLabel-root {
            margin-right: -11px !important;
            margin-left: 16px !important;
        }

        .MuiButton-startIcon {
            margin-left: 8px;
            margin-right: -4px;
        }
    }

    .css-9tj150-MuiButton-endIcon {
        display: inherit;
        margin-left: -4px;
        margin-right: 8px;
    }

    .MuiButton-endIcon {
        margin-left: -4px;
        margin-right: 8px;
    }

    .css-pl8gqh-MuiStack-root>:not(style)~:not(style) {
        margin-right: 16px;
    }

    .mr-0-5 {
        margin-left: 0.5rem;
        margin-right: 0;
    }

    .css-1vags01 {
        margin-right: -15px;
    }

    .css-urznti-MuiContainer-root {
        text-align: right;
    }

    .css-abrp7s-MuiInputBase-root {

        .MuiInputBase-input {
            padding-right: calc(1em + 32px);
            padding-left: 8px;
        }
    }



    .rcw-widget-container {
        margin: 0 0px 20px 20px;
        left: 0;
        right: auto;
    }

    .chat-sidebar {

        .MuiListItem-root {
            text-align: right;
        }

    }

    .MuiSnackbar-anchorOriginBottomLeft {
        right: 24px;
        left: auto;
    }

    .MuiSnackbarContent-action {
        padding-right: 16px !important;
        margin-left: -8px !important;
        margin-right: 0px !important;
        padding-left: 0px !important;
    }

    .sent {
        flex-direction: row !important;
    }

    .received {
        flex-direction: row-reverse;

    }

    .single-chat {
        p {
            text-align: right;
        }

        header {
            background-image: linear-gradient(90deg, white, $primary-color, $primary-color);
        }
    }

    .css-cpgvjg-MuiSnackbar-root {
        right: 24px;
        left: auto;
    }


    .list-chat {
        header {
            background-image: linear-gradient(90deg, white, $primary-color, $primary-color);
        }
    }

    .css-1d6wzja-MuiButton-startIcon {
        display: inherit;
        margin-left: 8px;
        margin-right: -4px;
    }

    .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
        text-align: right;
    }

    .css-1tbivq0-MuiStack-root>:not(style)~:not(style) {
        margin-right: 16px;
    }


    #modal-offer {

        .css-1p823my-MuiListItem-root {
            text-align: right;
        }

        .MuiListItemText-root {
            text-align: right;
        }

        .MuiButton-endIcon {
            margin-left: -4px;
            margin-right: 8px;
        }

    }

    header {

        .MuiButton-endIcon {
            margin-left: -4px !important;
            margin-right: 8px !important;
        }

    }

    .sidebar-responsive {

        .MuiPaper-root {
            right: 0px;
            left: auto;
        }

        * {
            text-align: right;
        }

    }

    p {
        text-align: right;
        font-size: 18px;
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        text-align: right;
    }

    label.MuiInputLabel-animated {

        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
        padding: 0;
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        position: relative;
        display: block;
        transform-origin: top right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 24px);
        position: absolute;
        right: 0;
        top: 0;
        left: auto;
        -webkit-transform: translate(-10px, 16px) scale(1);
        -moz-transform: translate(-10px, 16px) scale(1);
        -ms-transform: translate(-10px, 16px) scale(1);
        transform: translate(-10px, 16px) scale(1);
        -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        z-index: 1;
        pointer-events: none;

    }

    .category_list {
        margin-right: 1.5rem;
    }

    .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
        right: 0;
        left: unset;
        transform: translate(-9px, -9px) scale(1);
        font-size: 14px;
    }

    .MuiInputLabel-shrink {
        right: 0 !important;
        left: unset !important;
        transform: translate(-9px, -9px) scale(1) !important;
        font-size: 14px !important;
    }

    .MuiTextField-root {

        .MuiOutlinedInput-notchedOutline {
            text-align: right;
        }
    }


    .setting {
        .css-adcfpp-MuiAccordionActions-root {
            gap: 2%;
        }
    }

    .preloader-position {
        right: 50% !important;
        left: auto !important;
    }


    .copyright {
        text-align: center;
    }

    .css-155nyw6-MuiButton-endIcon {
        margin-left: -2px;
        margin-right: 8px;
    }

    .request_icon {
        margin-left: 8px;
    }


    .MuiDataGrid-main {

        .MuiDataGrid-cell--textLeft {
            text-align: right;
        }

    }

    .cancel-form {
        .MuiButton-colorPrimary {
            margin-right: 16px;
            margin-left: 0px;
        }
    }


    .add-request {

        .css-rxaxkm-MuiPaper-root-MuiAlert-root .MuiAlert-icon {
            color: #0288d1;
            margin-left: 12px;
            margin-right: 0px;
        }

        .MuiStepLabel-iconContainer {
            padding-left: 8px;
        }

        .MuiButton-outlinedPrimary {
            margin-right: 1px !important;
            margin-left: 8px !important;
        }


        .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon{

            .MuiOutlinedInput-root {
                padding-left: 65px !important;
                padding-right: 0px !important;
            }
        } 

        .MuiChip-deleteIcon {
            margin: 0 -6px 0 5px !important;
        }

        .css-1g9bazu-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
            -webkit-tap-highlight-color: transparent;
            color: rgba(0, 0, 0, 0.26);
            font-size: 22px;
            cursor: pointer;
            margin: 0 -6px 0 6px;
        }

        .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root {
            padding-left: 65px;
            padding-right: 0px;
        }
    }


    .address-modal {
        .MuiButton-outlinedPrimary {
            margin-left: 1px !important;
            margin-right: 8px !important;
        }
    }

    .add-new-address {
        .MuiButton-endIcon {
            margin-left: -4px !important;
            margin-right: 8px !important;
        }
    }

    .setting {
        .MuiButton-icon {
            margin-left: -4px !important;
            margin-right: 8px !important;
        }

        .email_address,
        .twitter {
            margin-right: 8px !important;
        }
    }

    .back-to-home {
        transform: rotate(180deg);
    }

    .review-request {

        .MuiCardHeader-avatar {
            margin-left: 16px !important;
            margin-right: 0px !important;
        }
    }


    .footer {
        * {
            text-align: right;
        }
    }


    .update-request {

        .MuiBadge-badge {
            top: 0 !important;
            left: 0 !important;
            transform: scale(1) translate(-50%, -50%) !important;
            transform-origin: 100% 0% !important;
            right: auto !important;
        }

    }


    .table-request,
    .product-table {

        .MuiTablePagination-actions {
            margin-right: 20px !important;
            margin-left: 0px !important;

            button {
                transform: rotate(180deg) !important;
            }
        }

        .MuiTablePagination-toolbar {
            padding-left: 2px !important;
            padding-right: 0px !important;
        }

        .MuiTablePagination-displayedRows {
            font-size: 15px;
            direction: ltr;
        }
    }



    .show-more {
        text-align: right;
    }

    .products {

        .MuiCardHeader-avatar {
            margin-left: 16px;
            margin-right: auto;
        }

        .MuiButton-endIcon {
            margin-left: -4px;
            margin-right: 8px;
        }


        .MuiButton-startIcon {
            margin-left: 8px;
            margin-right: -4px;
        }
    }

    .add_to_cart {
        .MuiButton-startIcon {
            margin-left: 8px;
            margin-right: -4px;
        }
    }


    .appbar-store {

        .Dropdown_dropdown__eusxd .Dropdown_menu-right__-2mDO {
            right: 0;
            left: auto;
        }

        ul {
            list-style: none;
            padding-right: 0;
            margin-top: 12px;
            margin-bottom: 12px;
        }

        .category-arrow {
            svg {
                transform: rotate(180deg);
            }
        }

    }

    .specification_key {
        text-align: right;
        font-weight: bold;
    }

    .specification_value {
        text-align: center;
    }

    .customer_review_name {
        text-align: right;
    }

    .out_of_5 {
        margin-right: 2%;
    }

    .starts_ar {
        direction: rtl;
        font-size: 1.2rem;
        color: #ffa726;
        text-align: right;
    }

    .cart-item-image {
        margin-left: 20px;
    }

    .remove-item {
        float: left;
    }

    .clear_cart {
        height: 30px;
        align-items: center;
        align-self: center;
        margin-right: 20px;
    }

    .icon-main-page {
        span {
            margin-left: -4px;
            margin-right: 8px;
        }
    }


    .form-store-seller {

        .attribute-form {

            .MuiAutocomplete-endAdornment {
                left: 9px;
                right: auto;
            }
        }



        .details-section {


            .MuiAutocomplete-endAdornment {
                left: 9px;
                right: auto;
            }

            .select-details {

                .MuiSvgIcon-root {
                    position: absolute;
                    right: 70% !important;
                }
            }



            .product-form-price {

                .MuiInputAdornment-root {
                    margin-right: 0px;
                    margin-left: 8px;
                }

            }
        }

    }


    .product-table {

        .product-details {
            right: 22%;
            left: auto;

            div {
                text-align: right;
            }
        }
    }

    fieldset > * {
        text-align: right;
    }

    .attribute-name {
        margin-right: -11px;
        margin-left: 16px;
    }


    .text-right {
        text-align: right;
    }

    .order-table {
        margin-top: 2%;


        .order-details {

            right: 17%;
            left: auto;

        }


        .order-details-seller {
            margin-top: 1%;
            position: relative;
            right: 15%;
            left: auto;
            width: 100%;
            margin-bottom: 1%;
        }

    }


    .category-gap-column{
        direction: ltr;
    }


    .sidebar-close{
        right: 90%;
        left: 0%;
        top: 3%;
        position: fixed;
    }


    .navbar-MuiTabScrollButton-horizontal{
        .MuiTabScrollButton-horizontal{
            display: none;
        }
    }


    .sale-price {
        left: 0px;
        right: 20px;
    }
    

    .slider-product-card{

        .product-card{
        
            .MuiButton-startIcon{
                margin-left: 8px;
                margin-right: -4px;
            }

            .product-title {
             text-align: right;
             direction: rtl;
            }

            .MuiButton-endIcon {
                margin-left: -4px;
                margin-right: 8px;
            }
            
            *{
                text-align: right;
                direction: rtl;
            }
      }
    }

    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        position: absolute;
        margin-top: -9px;
        left: 0;
        right: auto;
        top: 50%;
        width: 18px;
    }

    .fieldset-confirm{
        margin-right: 32px;
        margin-left: 0px;
    }


    .fulfillment-modal-content {

        .MuiChip-deleteIcon {
            margin: 0 -6px 0 5px;
        }

        .fulfillment_fee {
            text-align: center;
        }

        .MuiAutocomplete-endAdornment {
            left: 9px !important;
            right: auto !important;
        }

        .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1x6bjyf-MuiAutocomplete-root .MuiOutlinedInput-root {
            padding-left: 39px;
            padding-right: 10px;
        }


        .MuiSelect-iconOutlined{
            left: 7px;
            right: auto;
        }

    }

    .sold_info{
        margin-right: 16px;
        margin-left: 0px;
    }

    .MuiAlert-icon {
        margin-left: 12px;
        margin-right: 0px;
    }
}