
html {

    body {

        .businessType {

            div {
                width: 100%;
            }

        }
    }
}